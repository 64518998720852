import { Text } from '@intellecteu/common-ui';

import s from './styles.module.scss';

export const NoEntityData = ({
  className,
  entityName,
  message,
}: {
  className?: string;
  entityName: string;
  message?: string;
}) => {
  const defaultMessage = `You haven't added any ${entityName} yet.`;

  return (
    <Text className={className} size={Text.size.s} color={Text.color.darkGrey}>
      {message ?? defaultMessage}
    </Text>
  );
};

export const Onboarding = ({ message }: { message?: string }) => {
  const defaultMessage = 'To start working, please add your first node.';

  return (
    <div className={s.onboarding}>
      <div className={s.onboardingBox}>
        <Text size={Text.size.xl} color={Text.color.darkBlue} weight="regular">
          {message ?? defaultMessage}
        </Text>
      </div>
    </div>
  );
};
