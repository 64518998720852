/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreatedUserDto } from './CreatedUserDto';
import type { EnvVar } from './EnvVar';
import type { ResourcesDto } from './ResourcesDto';

export type ValidatorResponseDto = {
  application: string;
  applicationCantonNameServer: string;
  applicationWallet?: string;
  createdUser: CreatedUserDto;
  disableAutoInit?: boolean;
  envVars?: Array<EnvVar>;
  imageRepo?: string;
  imageTag?: string;
  migrationAttachPVC?: boolean;
  migrationId?: string;
  migrationMigrating?: boolean;
  name: string;
  onboardingSecretName?: string;
  participant: string;
  phase?: ValidatorResponseDto.phase;
  postgresPassword?: string;
  postgresUser: string;
  resources?: ResourcesDto;
  storageSize?: string;
  walletEnabled?: boolean;
};

export namespace ValidatorResponseDto {
  export enum phase {
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
  }
}
