import * as Yup from 'yup';
import cron from 'cron-validate';
import { backupScheduleCronOptions, enterpriseCronOptions } from 'app/constants/cron';
import { MemoryEnum } from '../constants';
import { EnvVar, StorageDto } from '../openapi';

// Regexp ----------------------------
const nameRegExp = /^[a-z]([a-z0-9.-]*[a-z0-9])?$/;
export const urlRegExp =
  /^((https?|ftp):\/\/)?([^\s:@]+(:[^\s:@]*)?@)?([^\s:@]+)(:[0-9]+)?(\/[^\s]*)?(\?[^\s#]*)?(#[^\s]*)?$/;
export const imageTagRegExp = /^(\d+)\.(\d+)(\.\d+)?$/;
export const noUpperCaseRegExp = /^[^A-Z]*$/;

const envVarRegExp = /^[\x20-\x7E\x80-\xFF]*$/;

// https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#meaning-of-cpu

const cpuResourcesRegExp = /^[0-9]+m$|^[0-9]+\.([0-9]{1,3})$|^[0-9]+$/;

const milliCpuRegExp = /^[0-9]+m$/;

// https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#meaning-of-memory

//  Helpers  ----------------------------
const unitSize = {
  [MemoryEnum.Ei]: 2 ** 60,
  [MemoryEnum.Pi]: 2 ** 50,
  [MemoryEnum.Ti]: 2 ** 40,
  [MemoryEnum.Gi]: 2 ** 30,
  [MemoryEnum.Mi]: 2 ** 20,
  [MemoryEnum.Ki]: 2 ** 10,
};

// Messages  ----------------------------
export const VALUE_NOT_VALID_ERROR_MESSAGE = 'Value is not valid.';
export const VALUE_NOT_EMPTY_ERROR_MESSAGE = 'Value should be not empty.';
export const VALUE_INTEGER_MESSAGE = 'Value should be integer.';
export const INTEGER_MIN_MESSAGE = 'Value should be greater than or equal to';
// const INTEGER_MAX_MESSAGE = 'Value should be less than or equal to';
export const FIELD_REQUIRED_ERROR_MESSAGE = 'Field is required.';
export const INVALID_FORMAT_MESSAGE = 'Invalid format.';
export const INVALID_URL_MESSAGE = 'Invalid url.';
export const DEFAULT_ALPHANUMERIC_VALIDATION_MESSAGE = `It should contain a lower case alphanumeric characters (a-z) or '-', or '.', and must start with an alphanumeric character.`;
export const DEFAULT_NAME_VALIDATION_MESSAGE = `${VALUE_NOT_VALID_ERROR_MESSAGE} ${DEFAULT_ALPHANUMERIC_VALIDATION_MESSAGE}`;

// Schemas  ----------------------------
export const envVarsArrSchema = Yup.array()
  .of(
    Yup.object({
      name: Yup.string().matches(envVarRegExp, 'Variable name is not valid').required('Variable name is required'),
      value: Yup.string().matches(envVarRegExp, 'Variable value is not valid'),
      valueFrom: Yup.object()
        .shape({
          secretKeyRef: Yup.object().shape({
            name: Yup.string()
              .test('secret value validation', 'Source value is required', (_, context) => !!context.originalValue)
              .matches(envVarRegExp, 'Variable secret name is not valid'),
            key: Yup.string()
              .test('secret key validation', 'Source key is required', (_, context) => !!context.originalValue)
              .matches(envVarRegExp, 'Variable secret key is not valid'),
            optional: Yup.boolean(),
          }),
        })
        .nullable(),
    }),
  )
  .test('unique names validation', 'Duplicate variable names are not allowed', (values, context) => {
    const envNames = values?.map((env) => env.name) || [];
    const postgresEnvNames = context.parent?.postgresEnvVars?.map((env) => env.name) || [];

    const resEnvVars = postgresEnvNames.length > 0 ? values?.concat(context.parent.postgresEnvVars) : values;
    const resEnvNames = postgresEnvNames.length > 0 ? [...envNames, ...postgresEnvNames] : envNames;

    return resEnvVars?.length === new Set(resEnvNames).size;
  });

export const envVarsSchema: Yup.ObjectSchema<{ envVars?: EnvVar[] }> = Yup.object().shape({
  envVars: envVarsArrSchema,
});

const memorySchemaFn = (msg: string) =>
  Yup.object().shape({
    value: Yup.string()
      .required(FIELD_REQUIRED_ERROR_MESSAGE)
      .test('memory validation for requested value', msg, (_, context) => {
        const limitSize =
          parseFloat(context?.from?.[1]?.value.memoryLimit?.value) *
          unitSize[context?.from?.[1]?.value.memoryLimit?.unit];
        const requestSize =
          parseFloat(context?.from?.[1]?.value.memoryRequested?.value) *
          unitSize[context?.from?.[1]?.value.memoryRequested?.unit];

        return limitSize >= requestSize;
      }),
  });

const cpuSchemaFn = (msg: string) =>
  Yup.string()
    .required(FIELD_REQUIRED_ERROR_MESSAGE)
    .matches(cpuResourcesRegExp, INVALID_FORMAT_MESSAGE)
    .test('CPU validation for requested value', msg, (_, context) => {
      const cpuRequested = context?.from?.[1].value.resources.cpuRequested;
      const cpuLimit = context?.from?.[1].value.resources.cpuLimit;
      const requestSize = milliCpuRegExp.test(cpuRequested)
        ? +cpuRequested.replace('m', '')
        : parseFloat(cpuRequested) * 1000;
      const limitSize = milliCpuRegExp.test(cpuLimit) ? +cpuLimit.replace('m', '') : parseFloat(cpuLimit) * 1000;

      return requestSize <= limitSize;
    });

export const getNameValidation = () => {
  return Yup.string()
    .max(30, 'Value should contain less or equal then 30 characters')
    .matches(nameRegExp, `${DEFAULT_NAME_VALIDATION_MESSAGE}`)
    .required(FIELD_REQUIRED_ERROR_MESSAGE);
};

export const resourcesSchema = Yup.object().shape({
  cpuLimit: cpuSchemaFn('CPU limit should be more or equal then Requested CPU'),
  cpuRequested: cpuSchemaFn('Requested CPU should be less or equal then CPU limit'),
  memoryRequested: memorySchemaFn('Requested memory should be less or equal then memory limit'),
  memoryLimit: memorySchemaFn('Memory limit should be more or equal then requested memory'),
  replicas: Yup.number()
    .integer(VALUE_INTEGER_MESSAGE)
    .min(1, `${INTEGER_MIN_MESSAGE} 1.`)
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
});

const sizeValidationSchema = Yup.object()
  .shape({
    value: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
    unit: Yup.mixed<MemoryEnum>().oneOf(Object.values(MemoryEnum)).required(),
  })
  .required(FIELD_REQUIRED_ERROR_MESSAGE);

export const storageSchema = Yup.object().shape({
  type: Yup.mixed<StorageDto.type>().oneOf(Object.values(StorageDto.type)).required(FIELD_REQUIRED_ERROR_MESSAGE),
  user: Yup.string().test(VALUE_NOT_EMPTY_ERROR_MESSAGE, FIELD_REQUIRED_ERROR_MESSAGE, (value, context) => {
    if ((context.parent as StorageDto).type !== StorageDto.type.MEMORY && !value) return false;
    return true;
  }),
  password: Yup.string().test(VALUE_NOT_EMPTY_ERROR_MESSAGE, FIELD_REQUIRED_ERROR_MESSAGE, (value, context) => {
    if ((context.parent as StorageDto).type !== StorageDto.type.MEMORY && !value) return false;
    return true;
  }),
  hostname: Yup.string().test(VALUE_NOT_EMPTY_ERROR_MESSAGE, FIELD_REQUIRED_ERROR_MESSAGE, (value, context) => {
    if ((context.parent as StorageDto).type === StorageDto.type.EXTERNAL && !value) return false;
    return true;
  }),
  port: Yup.string().test(VALUE_NOT_EMPTY_ERROR_MESSAGE, FIELD_REQUIRED_ERROR_MESSAGE, (value, context) => {
    if ((context.parent as StorageDto).type === StorageDto.type.EXTERNAL && !value) return false;
    return true;
  }),
  // during validation the size should be the next format and when we send it to
  // server change to string
  size: Yup.object().when('type', {
    is: (val: StorageDto.type) => val === StorageDto.type.POSTGRES,
    then: () => sizeValidationSchema,
  }),
  backupSize: Yup.object().when('type', {
    is: (val: StorageDto.type) => val !== StorageDto.type.MEMORY,
    then: () => sizeValidationSchema,
  }),
});

export const createSchema = Yup.object().shape({
  ...envVarsSchema.fields,
  image: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
  adminPort: Yup.number().required(FIELD_REQUIRED_ERROR_MESSAGE),
  storage: storageSchema,
  resources: resourcesSchema,
});

export const ParticipantUploadDarSchema = Yup.object().shape({
  darName: Yup.string().test('DAR File', 'Select the DAR file to upload.', (value, context) => {
    const file = context?.from?.[0].value.darName;
    if (!file || !value) return false;

    return true;
  }),
});

const isValid = (value) => value.split(' ').join('').length !== 0;

const rightsSchema = Yup.object().shape({
  rights: Yup.array().of(
    Yup.object().shape({
      type: Yup.string()
        .ensure() // Transforms undefined and null values to an empty string.
        .test('Type empty?', 'Type cannot be empty', (value) => {
          return isValid(value);
        }),
      party: Yup.string()
        .ensure()
        .test('Party empty?', 'Party cannot be empty', (value, ctx) => {
          if (ctx.parent.type !== 'ParticipantAdmin') {
            return isValid(value);
          }
          return true;
        }),
    }),
  ),
});

export const UserCreateSchema = Yup.object().shape({
  userId: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
  primaryParty: Yup.string(),
  ...rightsSchema.fields,
});

export const UserManageRightsSchema = Yup.object().shape({
  ...rightsSchema.fields,
});

export const DomainEditSchema = Yup.object().shape({
  image: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
  resources: resourcesSchema,
  ...envVarsSchema.fields,
});

export const ApplicationEditSchema = Yup.object().shape({
  resources: resourcesSchema,
});

export const ParticipantConnectSchema = Yup.object().shape({
  name: Yup.string(),
  domainAlias: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
  domainName: Yup.string().when('custom', {
    is: false,
    then: () => Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
  }),
  domainUrl: Yup.string().when('custom', { is: true, then: () => Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE) }),
  publicPort: Yup.string(),
  connected: Yup.boolean().nullable(),
  port: Yup.number(),
});

export const PartyCreateSchema = Yup.object().shape({
  displayName: Yup.string()
    .matches(nameRegExp, `${VALUE_NOT_VALID_ERROR_MESSAGE} ${DEFAULT_ALPHANUMERIC_VALIDATION_MESSAGE}`)
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
});

export const SchedulePruningSchema = Yup.object().shape({
  cron: Yup.string()
    .test('Cron valid??', 'Cron must be valid', (_, ctx) => {
      const cronOptions = ctx.parent.enterprise
        ? {
            ...enterpriseCronOptions,
            useYears: ctx.parent.year,
          }
        : {
            useAliases: true,
            useBlankDay: true,
          };

      const cronResult = cron(ctx.originalValue as string, {
        override: cronOptions,
      });

      if (cronResult.isValid()) {
        return true;
      }

      const errorsSet = new Set(cronResult.getError());

      return ctx.createError({
        message: Array.from(errorsSet).join(', '),
        path: ctx.path,
      });
    })
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
  retentionInSec: Yup.number()
    .integer(VALUE_INTEGER_MESSAGE)
    .min(1, `${INTEGER_MIN_MESSAGE} 1.`)
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
  maxDurationInSec: Yup.number()
    .integer(VALUE_INTEGER_MESSAGE)
    .min(1, `${INTEGER_MIN_MESSAGE} 1.`)
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
});

export const MediatorSchedulePruningSchema = Yup.object().shape({
  mediator: Yup.object().shape({
    ...SchedulePruningSchema.fields,
  }),
});

export const SequencerSchedulePruningSchema = Yup.object().shape({
  sequencer: Yup.object().shape({
    ...SchedulePruningSchema.fields,
  }),
});

export const ScheduleBackupSchema = Yup.object().shape({
  cron: Yup.string()
    .test('Is cron valid?', 'Cron must be valid', (_, ctx) => {
      const cronResult = cron(ctx.originalValue as string, {
        override: backupScheduleCronOptions,
      });

      if (cronResult.isValid()) {
        return true;
      }

      const errorsSet = new Set(cronResult.getError());

      return ctx.createError({
        message: Array.from(errorsSet).join(', '),
        path: ctx.path,
      });
    })
    .required(
      `${FIELD_REQUIRED_ERROR_MESSAGE} Syntax: minute (0-59), hour (0-23), day of the month (1-31), month (1-12, JAN to DEC), day of the week (0-6, Sunday to Saturday OR sun, mon, tue, wed, thu, fri, sat)`,
    ),
  maxBackups: Yup.number()
    .integer(VALUE_INTEGER_MESSAGE)
    .min(1, `${INTEGER_MIN_MESSAGE} 1.`)
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
});

export const uploadFileSchema = Yup.object({
  files: Yup.array().required(FIELD_REQUIRED_ERROR_MESSAGE),
});
