import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';

import s from './styles.module.scss';
import 'react-loading-skeleton/dist/skeleton.css';

type TableSkeletonProps = {
  className?: string;
};

export const TableSkeleton = ({ className }: TableSkeletonProps) => {
  return (
    <div className={cx(s.containerBody, s.tableSkeleton, className)}>
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
    </div>
  );
};
