export const ALL_VALUE = 'All';
export const DESC = 'DESC';
export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';
export const DISABLE = 'Disable';
export const ENABLE = 'Enable';
export const DISABLED = 'Disabled';
export const ENABLED = 'Enabled';
// filters
export const ALL_STORAGE = 'All storage';
export const ALL_STATUSES = 'All statuses';
export const ALL_HEALTH_STATUSES = 'All health statuses';
export const STORAGE = 'Storage';
export const STATUS = 'Status';
export const HEALTH_STATUS = 'Health status';
