import { Button, ButtonProps } from '@intellecteu/common-ui';
import cx from 'classnames';

import s from './styles.module.scss';

export type ConfirmFooterProps = {
  submitText?: string;
  cancelText?: string;
  onClose: () => void;
  onSubmit?: () => void;
  submitButtonProps?: ButtonProps;
  isLoading?: boolean;
  footerClassName?: string;
};

export const ConfirmFooter = ({
  submitText = 'Save',
  cancelText = 'Cancel',
  isLoading,
  onClose,
  onSubmit,
  footerClassName,
  submitButtonProps = {},
}: ConfirmFooterProps) => {
  return (
    <div className={cx(s.footer, footerClassName)}>
      {cancelText && (
        <Button variant="secondary" className={s.footerBtn} onClick={onClose} disabled={isLoading} data-cy="cancel-btn">
          {cancelText}
        </Button>
      )}
      <Button
        className={s.footerBtn}
        onClick={onSubmit}
        type={submitButtonProps.type || 'submit'}
        data-cy="submit-btn"
        isLoading={isLoading}
        {...submitButtonProps}
      >
        {submitText}
      </Button>
    </div>
  );
};
