// import { ErrorPayload } from 'data/types';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { ErrorType } from 'data/constants';

export type ApiStore = {
  error?: { type: ErrorType; payload?: string };
};

export const initialApiState = {};

export const apiStore = create(subscribeWithSelector<ApiStore>(() => initialApiState));
