import { BrowserRouter } from 'react-router-dom';
// TODO: decide if needed
import { Buffer } from 'buffer';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { createRoot } from 'react-dom/client';
import { Loader, BreadcrumbsProvider } from '@intellecteu/common-ui';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toast } from 'app/components/core';
import { useStore } from 'data';
import keycloak from 'data/keycloak';
import App from './pages';

import 'app/styles/global.module.scss';

window.Buffer = Buffer;

const container = document.getElementById('root') as HTMLElement;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // default value
      retryDelay: 3 * 1000,
    },
  },
});

export const Canton = () => {
  const { setAuthorization } = useStore();

  const eventLogger = (event: unknown) => {
    if (event && event === 'onAuthSuccess') {
      setAuthorization(keycloak);
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <BreadcrumbsProvider>
          <ReactKeycloakProvider
            initOptions={{ onLoad: 'login-required' }}
            LoadingComponent={<Loader.FullPage />}
            authClient={keycloak}
            onEvent={eventLogger}
          >
            <App />
            <Toast />
          </ReactKeycloakProvider>
        </BreadcrumbsProvider>
      </BrowserRouter>
      {import.meta.env.VITE_MODE === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

const root = createRoot(container);

root.render(<Canton />);
