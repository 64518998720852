import { memo } from 'react';
import cx from 'classnames';
import { InfoItem, SelectInput, TextInput, onSelectChangeAdapter, selectValueAdapter } from '@intellecteu/common-ui';
import { storage, memory } from 'app/constants';
import { handleIntegerInput } from 'app/utils/helpers';
import { StorageDto } from 'data';
import { Field } from '../ui/form/field';
import { StorageBackupSize } from './backup-size';

import s from './styles.module.scss';

type StorageProps = {
  type: StorageDto.type;
  className?: string;
  wrapperClassName?: string;
};

const StorageComponent = ({ type, className, wrapperClassName }: StorageProps) => (
  <div className={wrapperClassName}>
    <InfoItem
      className={s.formSidebarSectionTitle}
      label="Storage settings"
      labelSize="s"
      labelColor="dark"
      labelWeight="bold"
      component="p"
    />

    <div className={cx(s.formSidebarSection, className)}>
      <Field
        component={SelectInput}
        id="storage.type"
        name="storage.type"
        label="Storage*"
        options={storage}
        placeholder="Select storage..."
        onChangeAdapter={onSelectChangeAdapter}
        valueAdapter={selectValueAdapter(storage)}
      />

      <div />

      {type && type !== StorageDto.type.MEMORY && (
        <>
          <Field component={TextInput} id="storage.user" name="storage.user" label="User*" autoFocus data-1p-ignore />
          <Field
            component={TextInput}
            id="storage.password"
            name="storage.password"
            label="Password*"
            type="password"
            data-1p-ignore
          />
          {type === StorageDto.type.EXTERNAL && (
            <>
              <Field component={TextInput} id="storage.hostname" name="storage.hostname" label="Hostname*" />
              <Field component={TextInput} id="storage.port" name="storage.port" label="Port*" />
            </>
          )}
          {type === StorageDto.type.POSTGRES && (
            <div className={s.fieldGroup}>
              <Field
                className={s.fieldGroupInput}
                component={TextInput}
                id="storage.size.value"
                name="storage.size.value"
                label="Storage size*"
                onKeyPress={handleIntegerInput}
              />
              <Field
                className={s.fieldGroupSelect}
                options={memory}
                component={SelectInput}
                wrapperClassName={s.fieldGroupSelectContainer}
                id="storage.size.unit"
                name="storage.size.unit"
                onChangeAdapter={onSelectChangeAdapter}
                valueAdapter={selectValueAdapter(memory)}
                maxMenuHeight="200px"
                isMenuAbove
              />
            </div>
          )}

          <StorageBackupSize />
        </>
      )}
    </div>
  </div>
);

export const Storage = memo(StorageComponent);
