import { StorageDto } from 'data';

export const adminPortParticipant = '5019';
export const ledgerPortParticipant = '5011';
export const storageTypeParticipant = StorageDto.type.MEMORY;

export const storage = [
  {
    value: StorageDto.type.MEMORY,
    label: 'Memory',
  },
  {
    value: StorageDto.type.POSTGRES,
    label: 'PostgreSQL',
  },
  {
    value: StorageDto.type.EXTERNAL,
    label: 'External',
  },
];
