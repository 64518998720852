import { memo } from 'react';
import { onSelectChangeAdapter, SelectInput, selectValueAdapter, TextInput } from '@intellecteu/common-ui';
import { handleIntegerInput } from 'app/utils';
import { Labels, memory } from 'app/constants';
import { Field } from '../ui/form/field';

import s from './styles.module.scss';

export const BackupSizeComponent = () => (
  <div className={s.fieldGroup}>
    <Field
      className={s.fieldGroupInput}
      component={TextInput}
      id="storage.backupSize.value"
      name="storage.backupSize.value"
      label={`${Labels.STORAGE_BACKUP_SIZE}*`}
      onKeyPress={handleIntegerInput}
    />
    <Field
      className={s.fieldGroupSelect}
      options={memory}
      component={SelectInput}
      wrapperClassName={s.fieldGroupSelectContainer}
      id="storage.backupSize.unit"
      name="storage.backupSize.unit"
      onChangeAdapter={onSelectChangeAdapter}
      valueAdapter={selectValueAdapter(memory)}
      maxMenuHeight="200px"
      isMenuAbove
    />
  </div>
);

export const StorageBackupSize = memo(BackupSizeComponent);
